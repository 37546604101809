 // import ExpendableList from  "./expendable/ExpendableList"
import Expentest from  "./expendable/Expentest"

import './App.css';

function App() {
  return (
    <div className="App">
   
  
   <Expentest   />

    </div>
  );
}

export default App;
