import React, { useState, useEffect } from 'react'; 
import axios from 'axios'; 
 
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { styled } from '@mui/material/styles';
 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary  
  }));

  const Expentest = () => {
     const [GetExpendable, SetExpendable] = useState([0]);
 
     useEffect(() => {
        axios.get('http://sptest.sp-soft.co.kr/GetExpendable?Prod_Device_Type=1&Types=Y&Search=null')
    .then(response => {
            SetExpendable(response.data);
           });
   }); 

  function FormRow() {
    return (
      <React.Fragment> 
        { GetExpendable.map(GetExpendables => (
            <Grid item xs={3}>
            <Item > 
                <li key={GetExpendables.idx}>
                    <div>{GetExpendables.idx}</div>
                    <div><img src={GetExpendables.imG_IDX} alt="" style={{width:100}}></img> </div>
                    <div>{GetExpendables.proD_CD} </div>
                    <div>{GetExpendables.proD_DES} </div>
                </li>
            </Item>
 {/*           
 <Item>
<li>
<div>
 <Stack spacing={2} direction="row">
      <Button variant="contained">ADD</Button>
      <Button variant="outlined">BUY</Button>
    </Stack>
</div>
</li>

</Item>  
*/}
            </Grid>
        ))
}

           
        
    
        


      </React.Fragment>
    );
  }


 
 return (
 <>
   <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          <FormRow />
        </Grid>
        
         
      </Grid>
    </Box>
        <ul>
            { GetExpendable.map(GetExpendables => (
                <li key={GetExpendables.idx}>
                    <div>{GetExpendables.idx}</div>
                    <div>{GetExpendables.proD_DES}</div>
                 </li>
            ))
            }
             User1
        </ul>

          
      </Container>
    </React.Fragment>
       
        

        </>
    );

};

export default Expentest;